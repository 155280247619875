<template>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      <span> Procesando...</span>
    </v-overlay>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
v-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>