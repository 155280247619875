<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Alejandro Orozco Marulanda                                    ###### -->
<!-- ###### @date: Octubre 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">

     <!-- FILTROS -->
     <section class="d-flex">
      <!--Colaborador Evaluado-->
      <FilterCustomized
        v-model="buscar.idColaborador" v-if="verFiltros"
        :items="listaBusqueda.colaborador" item-value="id" item-text="name" label="Evaluado" :style="{ maxWidth: '38' }"
        @update:search-input= "(value) =>llenarLista('colaborador',listaBusqueda.colaborador,value,'idColaborador','nombre',false)"
        />
        <!-- Este botón abre un diálogo para agregar una nuevo registro de plan de desarrollo -->
         <div>
           <v-tooltip left color="success" :open-on-focus="false">
             <template v-slot:activator="{ props }">
                 <v-btn small text color="success" v-bind="props"
                     @mousedown.prevent="dialogoCrearPlan = true">
                     {{ verFiltros ? '' : 'Agregar ' }}
                     <v-icon> add </v-icon>
                 </v-btn>
             </template>
           <span>Agregar plan de desarrollo</span>
         </v-tooltip>
         </div> 
     </section>

    <!-- Dialogo para crear Plan de Desarrollo -->
    <v-dialog v-model="dialogoCrearPlan" transition="dialog-bottom-transition" max-width="45rem" persistent :scrollable="false">
      <v-card min-height="85vh">
        <v-card-title class="encabezado pt-1 pb-1">
          <span class="text-h6"> {{ tituloDialogo }} </span>
        </v-card-title>
        <v-card-text class="pt-3 pb-0" style="max-height: 75vh; overflow: auto">
          <v-form ref="itemForm">
            <!--Elemento para seleccionar la habilidad a desarrollar-->
            <FilterCustomized
              v-model="crearPlanDesarrollo.habilidad.idHabilidad" :rules="[rules.required]"
              :items="listaBusqueda.habilidad" item-value="id" item-text="name" label="Habilidad a Desarrollar"
            />
            <!--Elemento para seleccionar la habilidad a desarrollar-->
            <FilterCustomized
              v-model="crearPlanDesarrollo.estrategia.idEstrategia" class="mt-3" :rules="[rules.required]"
             :items="listaBusqueda.estrategia" item-value="id" item-text="name" label="Estratégia de Aprendizaje"
            />
            <!--Elemento para redactar las acciones a tomar-->
            <v-textarea label="Acciones" v-model="crearPlanDesarrollo.accion" rows="3" class="mt-3" 
              compact outlined color="primary" counter="250" :rules="[v => v? v.length <= 250 || 'Máximo 250 caracteres':'']">
            </v-textarea>
            <!--Elemento para redactar los resultados esperados-->
            <v-textarea label="Resultado Esperado" v-model="crearPlanDesarrollo.resultado" rows="3"
              compact outlined color="primary" counter="250" :rules="[v => v? v.length <= 250 || 'Máximo 250 caracteres':'']">
            </v-textarea>
            <div class="d-flex">
              <v-slider v-model="crearPlanDesarrollo.cumplimiento" min-width="70%"
                :min="0" :max="100" :step="5" label="% de Cumplimiento" color="primary" thumb-label>
              </v-slider>
              
              <div style="max-width: 30%;">
                <!--Elemento para seleccionar la fecha límite-->
                <v-menu v-model="menuFecha" transition="scale-transition" min-width="290px"
                  :close-on-content-click="false" :return-value="tempFecha" :nudge-top="25" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="me-2 campo" v-model="crearPlanDesarrollo.fechaFin" label="Fecha Límite" v-bind="attrs" v-on="on"
                      outlined dense readonly color="primary" :rules="[rules.required]">
                    </v-text-field>
                  </template>
                  <v-date-picker hide-header v-model="tempFecha" locale="es-co"
                    color="primary" @input="saveFecha" elevation="24">
                    <template v-slot:actions>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="cleanFecha" :disabled="!crearPlanDesarrollo.fechaFin">Borrar</v-btn>
                    </template>
                  </v-date-picker>
                </v-menu>            
              </div>
            </div>
          </v-form>

        </v-card-text>

        <!-- Botones del formulario -->
        <div class="d-flex justify-end mt-2 mb-2">
            <v-btn class="me-2" text color="error" @click="clear()" variant="text">
              Cerrar
            </v-btn>
            <v-btn text depressed :color="!formValid ? 'grey' : 'success'" :disabled="!formValid" @click="guardarItem()" variant="text">
              {{tituloDialogo}}
            </v-btn>
          </div>

      </v-card>
    </v-dialog>

    <!-- dialogo para cambiar el estado -->
    <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="23rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> {{ estado }} </span>
        </v-card-title>
        <v-card-text class="pt-5">
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" variant="text" @click="dialogoCambiarEstado = false">No</v-btn>
            <v-btn color="success" variant="flat" @click="guardarItem()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Tabla de items -->
    <v-data-table class="elevation mt-4" :items="tabla.items" :loading="tabla.loading" fixed-header
      :headers="tabla.headers" :footer-props="tabla.footerProps" :items-per-page="tabla.itemsPerPage"
      :page="tabla.page" @update:items-per-page="(i) => tabla.itemsPerPage = i" @update:page="(p) => tabla.page = p"
      :items-length="tabla.totalPage" height="64vh" @mouseenter="isMenuOpen = false">

    <template v-slot:[`item.fechaFin`]="{ item }">
      {{ formatDateToYMD(item.fechaFin) }}
    </template>

    <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip location="bottom" :color="item.eliminado === false ? 'orange' : 'grey'">
          <template v-slot:activator="{ props }">
            <v-btn class="ms-2 me-2" icon text :color="item.eliminado === false ? 'orange' : 'grey'" v-bind="props" 
              @click="item.eliminado === false && abrirDialogoEditar(item)">
              <v-icon>border_color</v-icon>
            </v-btn>
          </template>
        <span>Editar Plan</span>
      </v-tooltip>

      <v-tooltip :color="item.eliminado === false ? 'error' : 'success'">
        <template v-slot:activator="{ props }">
          <v-btn class="ms-2 me-2" icon text 
            :color="item.eliminado === false ? 'error' : 'success'" 
            v-bind="props" @click="abrirDialogoEstado(item)">
            <v-icon>{{ item.eliminado === false ? 'delete' : 'how_to_reg' }}</v-icon>
          </v-btn>
        </template>
        <span>{{ item.eliminado === false ? 'Eliminar' : 'Activar' }}</span>
      </v-tooltip>
    </template>

    <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
    </template>

    </v-data-table>

  </div>
</template>

<script>
import FilterCustomized from './FilterCustomized.vue';
import { mapState } from "vuex";

export default {
  name:'TablaPlanDesarrollo',
  props:{
    verFiltros:{
      type:Boolean,
      required:true
    },
    crearItem:{
      type:Object
    },
    validarPlan:{
      type:Object
    }
  },
  components:{
    FilterCustomized,
  },
  data(){
    return {
      snackbarValue: false,
      snackbarMessage: '',
      snackbarColor:false,
      dialogoCrearPlan : false,
      dialogoCambiarEstado: false,
      menuFecha: false,
      nombreItem: 'Plan de desarrollo',
      tempFecha: null,
      fecha: null,
      ruta: 'msa-hr/api/plan-desarrollo',
      tituloDialogo: 'Agregar',
      rules: {
        required: (v) => !!v || "Este campo es requerido."
      },
      buscar:{
        idColaborador:null,
      },
      crearPlanDesarrollo:{
        habilidad:{idHabilidad: null},
        estrategia:{idEstrategia:null},
        resultado:'',
        fechaFin:'',
        cumplimiento:0,
        accion:''
      },
      listaBusqueda:{
        colaborador:[],
        habilidad:[],
        estrategia:[]
      },
      listaCreacion:{
        habilidad:[],
        estrategia:[]
      },
      tabla:{
        loading: false,
        items: [],
        headers: [
          { text: "HABILIDAD A DESARROLLAR", width: "15%", sortable: false, align: "center", value: "habilidad.nombre"},
          { text: "ESTRATEGIA DE APRENDIZAJE", width: "15%", sortable: false, align: "center", value: "estrategia.nombre" },
          { text: "ACCIONES A TOMAR", width: "20%", sortable: false, align: "center", value: "accion" },
          { text: "RESULTADO ESPERADO", width: "20%", sortable: false, align: "center", value: "resultado" },
          { text: "FECHA LIMITE", width: "10%", sortable: false, align: "center", value: "fechaFin" },
          { text: "% CUMPLIMIENTO", width: "10%", sortable: false, align: "center", value: "cumplimiento" },
          { text: "ACCIONES", width: "10%", sortable: false, align: "center", value: "acciones"},
        ],
        itemsPerPageOptions:[10, 15, 20, 30],
        itemsPerPage: 10,
        page: 1,
        totalPage: 0,
      }
    }
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
    formValid() {
      const { habilidad, estrategia, resultado, accion, fechaFin } = this.crearPlanDesarrollo;
      return !!habilidad && !!estrategia && !!resultado && !!accion && !!fechaFin;
    },
    estado() {
      return !this.crearPlanDesarrollo.eliminado
        ? `¿Desea activar ${this.nombreItem}?`
        : `¿Desea eliminar ${this.nombreItem}?`;
    },
  },
  methods:{
    //Método para agregar o editar un nuevo item
    async guardarItem(){
      const item = {
        empresa:{idEmpresa:this.enterprise.code},
        habilidad:{idHabilidad:this.crearPlanDesarrollo.habilidad.idHabilidad},
        estrategia:{idEstrategia:this.crearPlanDesarrollo.estrategia.idEstrategia},
        colaborador:{idColaborador: this.crearItem.colaborador.idColaborador},
        resultado:this.crearPlanDesarrollo.resultado,
        fechaFin:this.crearPlanDesarrollo.fechaFin,
        cumplimiento:this.crearPlanDesarrollo.cumplimiento,
        accion:this.crearPlanDesarrollo.accion,
        eliminado:this.crearPlanDesarrollo.eliminado
      }

      if(this.crearPlanDesarrollo.idPlanDesarrollo){
        item.idPlanDesarrollo = this.crearPlanDesarrollo.idPlanDesarrollo;
        item.lastmodifiedby = this.auth.username.toUpperCase();
      }else{
        item.createdby = this.auth.username.toUpperCase();
      }
      
      await this.$http.post(`${this.ruta}/guardar`, item)
        .then(() => {
          this.listarItems(this.crearItem.colaborador.idColaborador);
          this.dialogoCrearPlan = false;
          this.clear();
        }).catch((error) => {
          console.log(error);
        });
    },
    //Función para abrir el dialogo de plan de desarrollo en modo de editar un item ya creado
    abrirDialogoEditar(item){
      this.tituloDialogo = 'Editar';
      console.log(item)
      this.crearPlanDesarrollo = {
        ...item,
        habilidad:{...item.habilidad},
        estrategia:{...item.estrategia},
        fechaFin:this.formatDateToYMD(item.fechaFin)
      };
      this.dialogoCrearPlan = true;
    },
    /**
     * Abre el diálogo para camboiar el estado del colaborador
     * @param {*} item - Objeto que contiene los datos del colaborador.
     */
     abrirDialogoEstado(item) {
      this.crearPlanDesarrollo = {
        ...item,
        habilidad:{...item.habilidad},
        estrategia:{...item.estrategia},
        eliminado: item.eliminado ? false : true,
      };
      this.dialogoCambiarEstado = true;
    },
    //Manejo de solicitudes al BackEnd
    async listar(nombre, Url, soloActivos,codStone,tipoCompetenciaValue) {
      let lista = await this.$http.get(`msa-hr/api/${Url}/listar`, {
        params: {
          idEmpresa: this.enterprise.code,
          nombre,
          codStone,
          tipoCompetenciaValue,
          nombreCompleto: nombre,
          eliminado: soloActivos ? !soloActivos : '',
          size: 250
        },
      });

      return lista.data.content ? lista.data.content : [];
    },

    //Método genérico para el Llenado de una lista para filtro
    async llenarLista(Url,listado,nombreBusqueda, nombreId, nombreItem = "nombre", soloActivos) {
      //Trae la lista desde el back
      const lista = await this.listar(nombreBusqueda, Url,soloActivos);
      // Vaciar el listado original
      listado.length = 0;
      // Llenar el listado con los nuevos elementos
      lista.forEach(item => {
        listado.push({
          name: `${item[nombreItem].trim()}${item.apellidos? ` ${item.apellidos.trim()}` : ''}` ,
          id: item[nombreId]
        });
      });
    },
    /**
     * Método que formatea la fecha seleccionada en el formulario.
     * @param date - fecha a formatear
     */
     formatDateToYMD(date) {
      if (!date) return '';
      const d = new Date(date);
      return d.toISOString().split('T')[0];
    },
     /**
     * Método que obtiene y lista los items de la base de datos utilizando criterios de paginación y filtrado.
     * La respuesta obtenida se utiliza para actualizar la tabla de items en el frontend,
     * asignando la lista de items a 'tabla.items' y la cantidad total de items a 'tabla.totalPage'.
     */
    async listarItems(idColaborador){
      this.tabla.loading = true;
      try{
          const response = await this.$http
          .get(`msa-hr/api/plan-desarrollo/listar`, {
            params: {
              idEmpresa: this.enterprise.code,
              idColaborador,
              eliminado:false,
              page: this.tabla.page - 1,
              size: this.tabla.itemsPerPage,
            },
          })
      
        this.tabla.items = response.data.content;
        this.tabla.totalPage = response.data.totalElements;
        this.tabla.loading = false;

        
      /* console.log(response.data.content)
      this.validarPlan = response.data.content ? response.data.content.some(item => {
        const fecha = new Date(item.fechaFin)
        console.log(fecha.getFullYear)
        console.log(this.crearItem.listaEvaluacion)
      }) : false; */

      //console.log(this.validarPlan)

      }catch(error) {
        console.log(error);
        this.tabla.loading = false;
      }
    },
    /**
     * Método que guarda la fecha final en el campo de entrada.
     */
     saveFecha() {
      this.menuFecha = false;
      this.crearPlanDesarrollo.fechaFin = this.formatDateToYMD(this.tempFecha);
      this.fecha = this.crearPlanDesarrollo.fechaFin;
    },
    /**
     * Método que limpia el campo de entrada de la fecha de final.
     */
     cleanFecha() {
      this.menuFecha = false;
      this.crearPlanDesarrollo.fechaFin = null;
      this.fecha = null;
      this.tempFecha = null;
    },
    clear(){
      this.tituloDialogo = 'Agregar'
      this.dialogoCrearPlan = false;
      this.dialogoCambiarEstado = false;
      this.crearPlanDesarrollo.idPlanDesarrollo = null;
      this.crearPlanDesarrollo.habilidad.idHabilidad = null;
      this.crearPlanDesarrollo.estrategia.idEstrategia = null;
      this.crearPlanDesarrollo.accion = '';
      this.crearPlanDesarrollo.resultado = '';
      this.crearPlanDesarrollo.cumplimiento = 0;
      this.cleanFecha();
    }
  },
  mounted() {
    Promise.all(
      [
        this.llenarLista('colaborador',this.listaBusqueda.colaborador,'','idColaborador','nombre',true),
        this.llenarLista('habilidad',this.listaBusqueda.habilidad,'','idHabilidad','nombre',true),
        this.llenarLista('estrategia',this.listaBusqueda.estrategia,'','idEstrategia','nombre',true),
        this.listarItems(this.crearItem.colaborador.idColaborador)
      ]
    )
  },
}
</script>

<style>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 85vh;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
</style>


